@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body {
    font-size: 20px;
    height: 100%;
}
#root,
.app,
.content {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow: auto !important;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor-pointer {
  cursor: pointer !important;
}

ul {
  margin: 0 !important;
  padding: 0 !important;
}

ul li { list-style: none; display: inline; }
/* ul li:after { content: " \00b7"; } */
ul li:last-child:after { content: none; }

.quotation-cart-items-total {
  border: 1px solid lightgray;
  border-radius: 0.5em;
  cursor: pointer;
}

/* .add-section {
  top: -25px;
  left: -20px;
} */

.cart-quantity{
  border-radius: 50%;
  color: white;
  top: -8px;
  right: 1.2em;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Mui-focused {
  color: gray !important;
  border-color: white !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: gray !important;
}

.quotation-cart-items {
  height: 100%;
  overflow: auto;
  padding: 1em;
}
.quotation-cart-items::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.tab.active {
  opacity: 30%;
  cursor: default;
}

.cantilever-part-navigate {
  width: 50px;
  height: 50px;
  border: 1px solid lightgray;
  padding: 4px;
  margin: 0 2px;
  border-radius: 50%;
  cursor: pointer;
}

.pallet-racking-part-navigate {
  width: 50px;
  height: 50px;
  border: 1px solid lightgray;
  padding: 4px;
  margin: 0 2px;
  border-radius: 50%;
  cursor: pointer;
}

.cogs{
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.blink {
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinker {  
from { opacity: 1; }
to { opacity: 0; }
}

/* Spinner animations */

/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}

/* GRID STYLING */

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* SPINNING CIRCLE */

.leo-border-1 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 100%;
  height: 100%;
  background-color: #37474faa;
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(251, 91, 83);
  background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 100%;
  height: 100%;
  background-color: #1d2630aa;
  border-radius: 50%;
}

/* ALTERNATING ORBITS */

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin .8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

/* X-ROTATING BOXES */

.configure-border-1 {
  width: 115px;
  height: 115px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb5b53;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
  width: 115px;
  height: 115px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(63,249,220);
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
}

/* PULSE BUBBLES */

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

/* SOLAR SYSTEM */

.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #fafbfC;
	border-radius: 50%;
} 

.earth-orbit {
	width: 165px;
	height: 165px;
  -webkit-animation: spin 12s linear 0s infinite;
}

.venus-orbit {
	width: 120px;
	height: 120px;
  -webkit-animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
	width: 90px;
	height: 90px;
  -webkit-animation: spin 3s linear 0s infinite;
}

.planet {
	position: absolute;
	top: -5px;
  width: 10px;
  height: 10px;
	border-radius: 50%;
  background-color: #3ff9dc;
}

.sun {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: #ffab91;
}

.leo {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.blue-orbit {
	width: 165px;
	height: 165px;
  border: 1px solid #91daffa5;
  -webkit-animation: spin3D 3s linear .2s infinite;
}

.green-orbit {
	width: 120px;
	height: 120px;
  border: 1px solid #91ffbfa5;
  -webkit-animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
	width: 90px;
	height: 90px;
  border: 1px solid #ffca91a5;
  -webkit-animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
	width: 60px;
	height: 60px;
  border: 2px solid #ffffff;
  -webkit-animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
}

.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #fb5b53;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin .5s linear 0s infinite;
}


@keyframes arrow-spin {
  100% {
    transform: rotate(179deg);
  }
}

@-webkit-keyframes arrow-spin {
  100% {
    -webkit-transform: rotate(179deg);
  }
}

.psoload,
.psoload *,
.psoload *:before,
.psoload *:after {
  box-sizing: border-box;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.psoload {
  position: relative;
  margin: 30px auto;
  height: 150px;
  width: 150px;
}

.psoload .straight,
.psoload .curve {
  position: absolute;
  top: 17.5%;
  left: 17.5%;
  width: 65%;
  height: 65%;
  border-radius: 100%;
  animation: arrow-spin 0.85s cubic-bezier(0.2, 0.8, 0.9, 0.1) infinite;
  -webkit-animation: arrow-spin 0.85s cubic-bezier(0.2, 0.8, 0.9, 0.1) infinite;
}

.psoload .straight:before,
.psoload .straight:after {
  content: '';
  position: absolute;
  width: 15%;
  border-bottom: 3px solid #eee;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.psoload .straight:before {
  top: 5px;
  left: 5px;
}

.psoload .straight:after {
  bottom: 5px;
  right: 5px;
}

.psoload .curve:before,
.psoload .curve:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 10px;
  border: solid 3px transparent;
  border-top-color: #eee;
  border-radius: 50%/10px 10px 0 0;
  z-index: 90001;
}

.psoload .curve:before {
  transform: rotate(-63deg) translateX(-27px) translateY(-4px);
  -webkit-transform: rotate(-63deg) translateX(-27px) translateY(-4px);
}

.psoload .curve:after {
  bottom: 5px;
  right: 5px;
  transform: rotate(115deg) translateX(-26px) translateY(-12px);
  -webkit-transform: rotate(115deg) translateX(-26px) translateY(-12px);
}

.psoload .center {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border-radius: 100%;
  border: 3px solid #eee;
}

.psoload .inner {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  animation: arrow-spin 0.85s cubic-bezier(0.2, 0.8, 0.9, 0.1) infinite reverse;
  -webkit-animation: arrow-spin 0.85s cubic-bezier(0.2, 0.8, 0.9, 0.1) infinite reverse;
}

.psoload .inner:before,
.psoload .inner:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-width: 11px;
  border-bottom-color: #eee;
}

.psoload .inner:before {
  top: 12px;
  left: 12px;
  transform: rotate(128deg);
  -webkit-transform: rotate(128deg);
}

.psoload .inner:after {
  bottom: 12px;
  right: 12px;
  transform: rotate(-48deg);
  -webkit-transform: rotate(-48deg);
}

@keyframes rotate {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}
@keyframes top {
    from {top:0;}
    to {top:-60%;}
}
@keyframes bottom {
    from {bottom:0;}
    to {bottom:-60%;}
}

#container {
    position: absolute;
    left:50%; top:50%;
    width: 120px;
    height: 120px;
    margin: -50px 0 0 -50px;
    -webkit-animation: rotate linear 3.2s infinite;
}

.sphere {
    position: absolute;
}
.sphere:before,
.sphere:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    background: tomato;
}
.sphere:before {
    top: 0;
    border-radius: 999px 999px 0 0;
    -webkit-animation: top ease .8s alternate infinite;
}
.sphere:after {
    bottom: 0;
    border-radius: 0 0 999px 999px;
    -webkit-animation: bottom ease .8s alternate infinite;
    background: skyblue;
}

.s-1 { top:0; right:0; bottom:0; left:0; }
.s-2 { top:10px; right:10px; bottom:10px; left:10px; }
.s-3 { top:20px; right:20px; bottom:20px; left:20px; }
.s-4 { top:30px; right:30px; bottom:30px; left:30px; }
.s-5 { top:40px; right:40px; bottom:40px; left:40px; }
.s-gold { top:50px; right:50px; bottom:50px; left:50px; }

.s-2:before,
.s-2:after {
    background: antiquewhite;
    -webkit-animation-delay: 50ms;
}
.s-3:before,
.s-3:after {
    -webkit-animation-delay: 100ms;
}
.s-4:before,
.s-4:after {
    background: antiquewhite;
    -webkit-animation-delay: 150ms;
}
.s-5:before,
.s-5:after {
    -webkit-animation-delay: 200ms;
}
.s-gold:before,
.s-gold:after {
    background: gold;
    box-shadow: 0 0 50px gold;
    -webkit-animation: none;
}


@keyframes wave {
  0% {opacity: 0; transform: translateY(15px)}
  50% {opacity: 1; transform: translateY(0)}
  100% {opacity: 0; transform: translateY(-15px)}
}
.coffee ul {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  width: 60px;
  text-align: center;
  animation: wave 2.5s infinite linear;
  animation-fill-mode: forwards;
}
.coffee li {
  display: inline-block;
  background: #FAF1D9;
  height: 30px;
  width: 9px;
  border-radius: 0 100%;
  transform: rotate(12deg);
}
.cup {
  background: #F2E3B4;
  width: 60px;
  height: 54px;
  border-radius: 0 0 50% 50%;
  position: relative;
}
.cup:before {
  content: "";
  position: absolute;
  width: 66px;
  height: 20px;
  border-radius: 50%;
  background: inherit;
  left: -3px;
  top: -10px;
}
.cup:after {
  content: "";
  position: absolute;
  width: 56px;
  height: 12px;
  border-radius: 50%;
  background: #3A2F28;
  left: 2px;
  top: -6px;
}
.coffee span {
  background: #F2E3B4;
  width: 13px;
  height: 20px;
  position: absolute; 
  right: -13px;
  top: 10px;
  border-radius: 0 50% 50% 0;
}
.coffee span:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  background: #BF7C2C;
  width: 10px;
  height: 12px;
  border-radius: 0 50% 50% 0;
}
.coffee span:after {
  content: "";
  position: absolute;
  top: 40px;
  left: -50px;
  background: #F2E3B4;
  width: 40px;
  height: 8px;
  border-radius: 50%;
}



.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

@-webkit-keyframes pulse {
  0% { width: 2em; margin-left: -1em; }
  75% { width: 2.5em; margin-left: -1.25em; }
  100% { width: 3em; margin-left: -1.5em; }
}
@-webkit-keyframes pulse {
  0% { width: 2em; margin-left: -1em; }
  75% { width: 2.5em; margin-left: -1.25em; }
  100% { width: 3em; margin-left: -1.5em; }
}
@-moz-keyframes pulse {
  0% { width: 2em; margin-left: -1em; }
  75% { width: 2.5em; margin-left: -1.25em; }
  100% { width: 3em; margin-left: -1.5em; }
}
@-o-keyframes pulse {
  0% { width: 2em; margin-left: -1em; }
  75% { width: 2.5em; margin-left: -1.25em; }
  100% { width: 3em; margin-left: -1.5em; }
}
@keyframes pulse {
  0% { width: 2em; margin-left: -1em; }
  75% { width: 2.5em; margin-left: -1.25em; }
  100% { width: 3em; margin-left: -1.5em; }
}

#ball {
  position: relative;
  background: #f7f7f7;
  top: 3em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  margin: 0 auto;
  z-index: 2;
  -webkit-animation: bounce .5s cubic-bezier(.63,.09,.75,.46) infinite alternate;
  -moz-animation:    bounce .5s cubic-bezier(.63,.09,.75,.46)  infinite alternate;
  -o-animation:      bounce .5s cubic-bezier(.63,.09,.75,.46)  infinite alternate;
  animation:         bounce .5s cubic-bezier(.63,.09,.75,.46)  infinite alternate;
}

@-webkit-keyframes bounce {
  0%, 10% { top: 3em; height: 3em; width: 3em; }
  15% { height: 3.15em; width: 2.9em; }
  35% { height: 4em; width: 2.5em; }
  75% { height: 4em; width: 2.5em; }
  95% { height: 2em; width: 3.5em; }
  100% { top: 18em; height: 1em; width: 3em; }
}
@-moz-keyframes bounce {
  0%, 10% { top: 3em; height: 3em; width: 3em; }
  15% { height: 3.15em; width: 2.9em; }
  35% { height: 4em; width: 2.5em; }
  75% { height: 4em; width: 2.5em; }
  95% { height: 2em; width: 3.5em; }
  100% { top: 18em; height: 1em; width: 3em; }
}
@-o-keyframes bounce {
  0%, 10% { top: 3em; height: 3em; width: 3em; }
  15% { height: 3.15em; width: 2.9em; }
  35% { height: 4em; width: 2.5em; }
  75% { height: 4em; width: 2.5em; }
  95% { height: 2em; width: 3.5em; }
  100% { top: 18em; height: 1em; width: 3em; }
}
@keyframes bounce {
  0%, 10% { top: 3em; height: 3em; width: 3em; }
  15% { height: 3.15em; width: 2.9em; }
  35% { height: 4em; width: 2.5em; }
  75% { height: 4em; width: 2.5em; }
  95% { height: 2em; width: 3.5em; }
  100% { top: 18em; height: 1em; width: 3em; }
}